<template>
  <div>
    <div v-if="loading" class="loading-container pt-12">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </div>
    <v-form
      @submit.prevent="save"
      ref="form"
      v-show="!loading"
      :disabled="$attrs.disabled"
    >
      <ErrorMessages :messages="errors"></ErrorMessages>
      <v-row class="max-width">
        <v-col>
          <v-text-field
            v-model="form.name"
            :rules="$rules.required"
            label="Nome"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
        <v-col>
          <v-text-field
            v-model="form.subject"
            :rules="$rules.required"
            label="Assunto"
            required
            dense
            outlined
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col>
          <v-tabs class="nj-tabs" v-model="tab">
            <v-tab>Corpo</v-tab>
          </v-tabs>
          <v-tabs-items v-model="tab" eager>
            <v-tab-item class="py-4" eager>
              <nj-editor
                :disabled="$attrs.disabled"
                ref="bodyEditor"
                :custom-events="customEditorEvents"
                v-model="form.body"
              >
              </nj-editor>
            </v-tab-item>
          </v-tabs-items>
        </v-col>
      </v-row>

      <slot></slot>
    </v-form>
  </div>
</template>
<style scoped>
.max-width {
  max-width: 600px;
}
</style>
<script>
import axios from 'axios'
import Api from '@/resources/NajaSocialApi'
import EditorUpload from '@/resources/editorUpload'
import ErrorMessages from '@/components/ErrorMessages'

export default {
  name: 'EmailTemplateForm',
  components: { ErrorMessages },
  data: () => ({
    saveLoading: false,
    backgroundFile: null,
    backgroundURL: null,
    removingBackground: false,
    loading: false,
    tab: 0,
    errors: [],
    branches: [],
    subgroups: [],
    customEditorEvents: {
      'image.beforeUpload': EditorUpload.beforeUpload,
    },
    form: {
      name: null,
      subject: null,
      body: null,
    },
  }),

  mounted() {
    this.fetch(this.$route.params.id)
  },

  methods: {
    remove() {
      this.$refs.removeQuestionDialog.open({
        onConfirm: () => {
          this.$refs.removeQuestionDialog.toggleLoading()
          Api.roles
            .destroy(this.$route.params.id)
            .then(async () => {
              this.$router.push({ name: 'TaskSchedulers' })
            })
            .catch(error => {
              if (error.response) {
                this.$toast.show(
                  'Erro ao remover Agendamento de Tarefas',
                  'error'
                )
                // this._setErrors(error.response.data.errors);
              } else {
                throw error
              }
            })
            .finally(() => {
              this.$refs.removeQuestionDialog.close()
            })
        },
      })
    },

    _parseForm(data) {
      const formPayload = {
        id: data.id,
        name: data.name,
        subject: data.subject,
        body: data.body,
      }

      this.form = { ...this.form, ...formPayload }
    },

    async fetch(id = null) {
      try {
        if (id === null) {
          return
        }

        this.loading = true

        const templateResult = await Api.emailTemplates.fetch(id)
        this._parseForm(templateResult.data.email_template)
      } catch (e) {
        this.$root.$children[0].toast('Erro ao buscar Template de E-mail')
        throw e
      } finally {
        this.$scrollTop()
        this.loading = false
      }
    },

    save() {
      this.saveLoading = true

      Api.emailTemplates
        .save(this.$route.params.id, {
          email_template: this.form,
        })
        .then(response => {
          this._parseForm(response.data.email_template)
          this.$root.$children[0].toast('Template de E-mail salvo com sucesso')

          this.$router.push({
            name: 'EmailTemplateShow',
            params: { id: this.form.id },
          })
        })
        .catch(error => {
          if (error.response) {
            this.$root.$children[0].toast('Erro ao salvar Template de E-mail')
            if (error.response.status != 500) {
              // this._setErrors(error.response.data.errors);
              this.errors = error.response.data.errors

              this.$refs.form.validate()
            }
          } else {
            throw error
          }

          // throw error;
        })
        .finally(() => {
          this.$scrollTop()
          this.saveLoading = false
        })
    },
  },
}
</script>
